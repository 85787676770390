import * as React from 'react';
import type { SVGProps } from 'react';

const SvgSupportOperator = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M21 12.22a9 9 0 1 0-18 .06c-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1a7 7 0 1 1 14 0V19h-8v2h8a2 2 0 0 0 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62" />
    <path d="M9 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2m6 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
    <path d="M18 11.03a6.04 6.04 0 0 0-11.98 1.42 8.07 8.07 0 0 0 4.86-5.89A8.04 8.04 0 0 0 18 11.03" />
  </svg>
);
export default SvgSupportOperator;
