import { useEffect } from 'react';

import { type Visit } from '@m/types/api';
import { useHistory, useParams } from 'react-router-dom';

/**
 * Will redirect to the receipt page if the visit is already paid
 */
export function useClosedVisitRedirect(visit: Visit | null) {
  const history = useHistory();
  const { visitCode } = useParams<{ visitCode: string }>();

  useEffect(() => {
    if (visit && !visit.open) {
      history.replace(`/${visitCode}/receipt`);
    }
  }, [visit, visitCode, history]);
}
