import initApp from '@m/utils/initApp';
import { createRoot } from 'react-dom/client';

import { PayApp } from 'apps/pay';

import * as serviceWorker from './serviceWorker';

initApp('pay');

const root = createRoot(document.getElementById('root')!);

root.render(<PayApp />);

document.getElementById('initial-loader')?.remove();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
