import { Box } from '@m/components/uikit';
import { useTheme } from 'styled-components';

import { Text } from '../Text';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

interface LineItemProps extends StyledSystemProps {
  label: React.ReactNode;
  value: React.ReactNode;
  secondaryLabel?: React.ReactNode;
  emphasis?: boolean;
}

export function LineItem({ label, value, secondaryLabel, emphasis, ...boxProps }: LineItemProps) {
  const { colors } = useTheme();
  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      align-items="center"
      width="100%"
      paddingY="16px"
      {...boxProps}
    >
      <Text fontSize="18px" fontWeight={emphasis ? 600 : 400} color="#000" lineHeight={1.2}>
        {label}
        {secondaryLabel && (
          <div
            css={`
              font-weight: 400;
              color: ${colors.grey5};
            `}
          >
            {secondaryLabel}
          </div>
        )}
      </Text>
      <Text fontSize="18px" fontWeight={emphasis ? 600 : 400} color="#000" lineHeight={1.2}>
        {value}
      </Text>
    </Box>
  );
}
