import Wordmark from '@m/assets/svg/wordmark-latest';
import withStyledSystem from '@m/theme/helpers/withStyledSystem';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

export const Box = styled.div<StyledSystemProps>`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 24px;
  flex-shrink: 0;
  ${withStyledSystem}
`;

export const Logo = styled(Wordmark)<StyledSystemProps>`
  display: block;
  transition: fill 250ms linear 0ms;
  ${withStyledSystem}
`;
