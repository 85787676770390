import withStyledSystem from '@m/theme/helpers/withStyledSystem';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

export const Text = styled.span<StyledSystemProps>`
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  ${withStyledSystem}
`;
