import LoadingSpinner from '@m/components/LoadingSpinner';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { type PaymentRequest } from '@stripe/stripe-js';

import * as Styled from './ExpressCheckout.styled';

interface ExpressCheckoutProps {
  supportsPaymentRequest: boolean | null;
  stripePaymentRequest?: PaymentRequest;
}

export function ExpressCheckout({
  supportsPaymentRequest,
  stripePaymentRequest,
}: ExpressCheckoutProps) {
  return supportsPaymentRequest ? (
    <Styled.Box>
      <Styled.Frame>
        <Styled.Heading>Express checkout</Styled.Heading>
        <Styled.ButtonBox>
          {stripePaymentRequest && (
            <PaymentRequestButtonElement
              options={{
                paymentRequest: stripePaymentRequest,
                style: {
                  paymentRequestButton: {
                    height: '48px',
                  },
                },
              }}
            />
          )}
          {supportsPaymentRequest === null && (
            <Styled.LoadingBox>
              <LoadingSpinner />
            </Styled.LoadingBox>
          )}
        </Styled.ButtonBox>
      </Styled.Frame>
    </Styled.Box>
  ) : null;
}
