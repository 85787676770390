import * as React from 'react';
import type { SVGProps } from 'react';

const SvgChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M13.94 12.06 6 20l2.12 2.12 10.06-10.06L8.12 2 6 4.12z" />
  </svg>
);
export default SvgChevronRight;
