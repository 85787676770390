import { spin } from '@m/styled/keyframes';
import styled from 'styled-components';

export const LoadingIcon = styled.div<{ size: number; color: string; ringOpacity: number }>`
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: ${({ size }) => size / 12}px solid transparent;

  /* Color of spinner */
  color: ${({ color }) => color} !important;
  border-top: ${({ size }) => size / 12}px solid currentColor;
  border-radius: 50%;
  width: ${({ size }) => size}px !important;
  height: ${({ size }) => size}px !important;
  animation: ${spin} 1s linear infinite;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 ${({ size }) => size / 12}px currentColor;
    opacity: ${({ ringOpacity }) => ringOpacity};
    border-radius: 50%;
  }

  &::before {
    opacity: ${({ ringOpacity }) => Math.max(ringOpacity - 0.5, 0)};
    filter: invert();
  }
`;
