import { lazy, Suspense, useCallback, useEffect, useState } from 'react';

import { heapTrackProperties } from '@m/utils/heap';
import { stripePromise } from '@m/utils/stripeLoader';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { type PaymentRequest, type PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import { Portal } from 'react-portal';
import { useHistory, useParams } from 'react-router-dom';
import { useBeforeUnload, useLockBodyScroll, useToggle } from 'react-use';

import { VisitInfo, ExpressCheckout, PayWithCard } from 'apps/pay/components';
import { useClosedVisitRedirect, useVisitDetails } from 'apps/pay/hooks';
import { PaymentService } from 'apps/pay/services';

const VehicleConfirmModal = lazy(() => import('apps/pay/screens/VehicleConfirmModal'));
const NotMyVehicleConfirmModal = lazy(() => import('apps/pay/screens/NotMyVehicleConfirm'));
const ServiceFeeModal = lazy(() => import('apps/pay/screens/ServiceFeeInfo'));

function VisitLandingPage() {
  const history = useHistory();
  const { visitCode } = useParams<{ visitCode: string }>();
  const visit = useVisitDetails({ visitCode });

  useClosedVisitRedirect(visit);

  const stripe = useStripe();

  const [supportsPaymentRequest, setSupportsPaymentRequest] = useState<boolean | null>(null);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();

  const [renderVehicleConfirm, setRenderVehicleConfirm] = useState(false);
  const [showVehicleConfirm, setShowVehicleConfirm] = useState(false);

  const [renderVehicleDenyConfirm, setRenderVehicleDenyConfirm] = useState(false);
  const [showVehicleDenyConfirm, setShowVehicleDenyConfirm] = useState(false);

  const [renderServiceFeeInfo, setRenderServiceFeeInfo] = useState(false);
  const [showServiceFeeInfo, setShowServiceFeeInfo] = useState(false);
  const [locked, toggleLocked] = useToggle(false);
  useLockBodyScroll(locked);

  const itemizedAmounts = visit?.itemizedAmounts;

  const openVehicleConfirm = useCallback(() => {
    toggleLocked(true);
    setRenderVehicleConfirm(true);
    setShowVehicleConfirm(true);

    heapTrackProperties('memberless-payment/not-my-vehicle:opened', {
      'timestamp(utc)': new Date().toUTCString(),
      timestamp: Date.now(),
    });
  }, [toggleLocked]);

  const closeVehicleConfirm = useCallback(
    ({ track }: { track: boolean } = { track: true }) => {
      setShowVehicleConfirm(false);
      toggleLocked(false);
      if (track) {
        heapTrackProperties('memberless-payment/not-my-vehicle:closed', {
          'timestamp(utc)': new Date().toUTCString(),
          timestamp: Date.now(),
        });
      }
    },
    [toggleLocked],
  );

  const closeCompleteVehicleConfirm = useCallback(() => {
    setRenderVehicleConfirm(false);
  }, []);

  const openDenyUserVehicle = useCallback(() => {
    setRenderVehicleDenyConfirm(true);
    setShowVehicleDenyConfirm(true);

    heapTrackProperties('memberless-payment/not-my-vehicle/confirmation:opened', {
      'timestamp(utc)': new Date().toUTCString(),
      timestamp: Date.now(),
    });
  }, []);

  const closeCompleteDenyUserVehicle = useCallback(() => {
    setRenderVehicleDenyConfirm(false);
  }, []);

  const denyVehicleGoBack = useCallback(() => {
    setShowVehicleDenyConfirm(false);
    heapTrackProperties('memberless-payment/not-my-vehicle/confirmation:canceled', {
      'timestamp(utc)': new Date().toUTCString(),
      timestamp: Date.now(),
    });
    closeVehicleConfirm();
  }, [closeVehicleConfirm]);

  const denyVehicleConfirm = useCallback(async () => {
    setShowVehicleDenyConfirm(false);
    closeVehicleConfirm({ track: false });

    heapTrackProperties('memberless-payment/not-my-vehicle/confirmation:confirmed', {
      'timestamp(utc)': new Date().toUTCString(),
      timestamp: Date.now(),
    });

    await PaymentService.wrongVehicle({ visitCode });
    setTimeout(() => {
      history.replace(`/${visitCode}/drive-out`);
    }, 300);
  }, [closeVehicleConfirm, history, visitCode]);

  const openServiceFeeInfo = useCallback(() => {
    toggleLocked(true);
    setRenderServiceFeeInfo(true);
    setShowServiceFeeInfo(true);
  }, [toggleLocked]);

  const closeServiceFeeInfo = useCallback(() => {
    setShowServiceFeeInfo(false);
    toggleLocked(false);
  }, [toggleLocked]);

  const closeCompleteServiceFeeInfo = useCallback(() => {
    setRenderServiceFeeInfo(false);
  }, []);

  const completeVisit = useCallback(() => {
    history.replace(`/${visitCode}/complete`);
  }, [history, visitCode]);

  const handleCardPayment = useCallback(
    async (token: string) => {
      const { success } = await PaymentService.completePayment({
        visitCode,
        token,
        isBrowserSavedCard: false,
        saveForLater: false,
      });
      if (success) {
        heapTrackProperties('memberless-payment/pay(card):complete', {
          'timestamp(utc)': new Date().toUTCString(),
          timestamp: Date.now(),
        });
        completeVisit();
      } else {
        heapTrackProperties('memberless-payment/pay(card):failed', {
          'timestamp(utc)': new Date().toUTCString(),
          timestamp: Date.now(),
        });
      }
    },
    [visitCode, completeVisit],
  );

  const handleBrowserPayment = useCallback(
    async (e: PaymentRequestPaymentMethodEvent) => {
      const token = e.paymentMethod.id;
      const { success } = await PaymentService.completePayment({
        visitCode,
        token,
        isBrowserSavedCard: true,
        saveForLater: false,
      });
      if (success) {
        e.complete('success');
        heapTrackProperties('memberless-payment/pay(managed):complete', {
          'timestamp(utc)': new Date().toUTCString(),
          timestamp: Date.now(),
        });
        completeVisit();
      } else {
        e.complete('fail');
        heapTrackProperties('memberless-payment/pay(managed):failed', {
          'timestamp(utc)': new Date().toUTCString(),
          timestamp: Date.now(),
        });
      }
    },
    [completeVisit, visitCode],
  );

  const handleBeforeUnload = useCallback(() => {
    if (visit && visit.open) {
      heapTrackProperties('memberless-payment/landing:abandoned', {
        'timestamp(utc)': new Date().toUTCString(),
        timestamp: Date.now(),
      });
    }
    return false;
  }, [visit]);

  useBeforeUnload(handleBeforeUnload);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (visit) {
      // Wrapping in setTimeout so that this event is
      // not triggered if the page is to be redirected
      // to the receipt page
      timeoutId = setTimeout(() => {
        heapTrackProperties('memberless-payment/landing:viewed', {
          'timestamp(utc)': new Date().toUTCString(),
          timestamp: Date.now(),
        });
      }, 300);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [visit]);

  useEffect(() => {
    async function loadPaymentRequest() {
      if (itemizedAmounts && stripe && !paymentRequest) {
        const amount = parseFloat((itemizedAmounts.totalPriceAmount * 100).toFixed(2));
        const parkingCost = parseFloat((itemizedAmounts.price * 100).toFixed(2));

        const displayItems = [
          {
            label: 'Parking cost',
            amount: parkingCost,
          },
        ];

        if (itemizedAmounts.validationAmount) {
          displayItems.push({
            label: 'Validation',
            amount: parseFloat((0 - itemizedAmounts.validationAmount * 100).toFixed(2)),
          });
        }

        if (itemizedAmounts.taxAmount) {
          displayItems.push({
            label: 'Tax',
            amount: parseFloat((itemizedAmounts.taxAmount * 100).toFixed(2)),
          });
        }

        if (itemizedAmounts.feeAmount) {
          displayItems.push({
            label: 'Service fee',
            amount: parseFloat((itemizedAmounts.feeAmount * 100).toFixed(2)),
          });
        }

        const paymentReq = stripe.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: 'Metropolis Parking',
            amount,
          },
          displayItems,
          requestPayerName: true,
          requestPayerPhone: true,
        });

        try {
          const result = await paymentReq.canMakePayment();

          if (result) {
            setPaymentRequest(paymentReq);
            setSupportsPaymentRequest(true);
          } else {
            setSupportsPaymentRequest(false);
          }
        } catch (e) {
          setSupportsPaymentRequest(false);
        }

        paymentReq.on('paymentmethod', handleBrowserPayment);
      }
    }

    loadPaymentRequest();
  }, [handleBrowserPayment, itemizedAmounts, paymentRequest, stripe]);

  return (
    <>
      <VisitInfo
        visit={visit}
        onClickNotMyVehicle={openVehicleConfirm}
        onClickServiceFeeInfo={openServiceFeeInfo}
      />
      <ExpressCheckout
        supportsPaymentRequest={supportsPaymentRequest}
        stripePaymentRequest={paymentRequest}
      />
      <PayWithCard
        visit={visit}
        supportsPaymentRequest={supportsPaymentRequest}
        onCardTokenReceived={handleCardPayment}
      />
      <Suspense fallback={null}>
        {renderVehicleConfirm && (
          <Portal>
            <VehicleConfirmModal
              visit={visit}
              show={showVehicleConfirm}
              onClose={closeVehicleConfirm}
              onCloseComplete={closeCompleteVehicleConfirm}
              onConfirmVehicle={closeVehicleConfirm}
              onDenyVehicle={openDenyUserVehicle}
            />
          </Portal>
        )}
      </Suspense>
      <Suspense fallback={null}>
        {renderVehicleDenyConfirm && (
          <Portal>
            <NotMyVehicleConfirmModal
              visit={visit}
              show={showVehicleDenyConfirm}
              onClose={denyVehicleGoBack}
              onCloseComplete={closeCompleteDenyUserVehicle}
              onConfirmNotVehicle={denyVehicleConfirm}
            />
          </Portal>
        )}
      </Suspense>
      <Suspense fallback={null}>
        {renderServiceFeeInfo && (
          <Portal>
            <ServiceFeeModal
              show={showServiceFeeInfo}
              onClose={closeServiceFeeInfo}
              onCloseComplete={closeCompleteServiceFeeInfo}
            />
          </Portal>
        )}
      </Suspense>
    </>
  );
}

export default function StripeElementsVisitLandingPage() {
  return (
    <Elements stripe={stripePromise}>
      <VisitLandingPage />
    </Elements>
  );
}
