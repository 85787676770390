import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPaymentDinersclub = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="24"
    fill="none"
    data-name="Diner's Club"
    viewBox="0 0 34 24"
    {...props}
  >
    <rect width={33} height={23} x={0.5} y={0.5} fill="#fff" stroke="#D9D9D9" rx={3.5} />
    <path
      fill="#0079BE"
      fillRule="evenodd"
      d="M15.838 19.3h2.597c4.052 0 7.281-3.215 7.408-7.187v-.063c-.063-4.034-3.356-7.312-7.408-7.25h-2.913c-3.863.127-6.902 3.405-6.775 7.314 0 3.908 3.166 7.123 7.091 7.186m-5.825-7.25c0-3.278 2.723-5.988 6.015-5.988 3.293 0 6.016 2.71 6.016 5.988s-2.723 5.99-6.016 5.99c-3.292 0-6.015-2.711-6.015-5.99m6.965 4.035V8.079c2.216.567 3.61 2.774 3.04 4.98a4.11 4.11 0 0 1-3.04 3.026m-4.875-5.043a4.14 4.14 0 0 0 2.976 5.043V8.079a4.16 4.16 0 0 0-2.976 2.963"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPaymentDinersclub;
