import { fontDefault } from '@m/styled/fonts';
import theme from '@m/theme/theme';

export const cardOptions = {
  style: {
    base: {
      fontSize: '16px',
      fontWeight: 400,
      color: '#000',
      fontFamily: fontDefault,
    },
    invalid: {
      color: theme.colors.coral,
    },
  },
};
