import { Box } from '@m/components/uikit';
import { type Visit } from '@m/types/api';
import { DateTime } from 'luxon';

import { Text } from '../Text';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

interface PaidTimeProps extends StyledSystemProps {
  paymentTimestamp: Visit['updatedAt'];
}

export function PaidTime({ paymentTimestamp, ...boxProps }: PaidTimeProps) {
  const paymentDateTime = DateTime.fromMillis(paymentTimestamp);
  const paymentDate = paymentDateTime.toFormat('MMM d, yyyy');
  const paymentTime = paymentDateTime.toFormat('h:mm a');

  return (
    <Box
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      width="100%"
      {...boxProps}
    >
      <Text fontSize="16px" line-height={1.2} color="#06944F" marginRight="4px">
        Paid
      </Text>
      <Text fontSize="16px" line-height={1.2} color="#6A6A6A" marginRight="4px">
        &bull;
      </Text>
      <Text fontSize="16px" line-height={1.2} color="#6A6A6A" marginRight="4px">
        {paymentDate} at
        {paymentTime}
      </Text>
    </Box>
  );
}
