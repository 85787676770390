import { createGlobalStyle } from 'styled-components';

import TTHovesBoldWoff2 from './TTHoves-Bold.woff2';
import TTHovesDemiBoldWoff2 from './TTHoves-DemiBold.woff2';
import TTHovesMediumWoff2 from './TTHoves-Medium.woff2';
import TTHovesRegularWoff2 from './TTHoves-Regular.woff2';

export const FontTTHoves = createGlobalStyle`
  @font-face {
    font-family: 'TT Hoves';
    src:
      url(${TTHovesBoldWoff2}) format('woff2');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Hoves';
    src:
      url(${TTHovesDemiBoldWoff2}) format('woff2');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Hoves';
    src:
      url(${TTHovesMediumWoff2}) format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Hoves';
    src:
      url(${TTHovesRegularWoff2}) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
`;
