import * as Styled from './DividerLabel.styled';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

interface DividerLabelProps extends StyledSystemProps {
  labelProps?: StyledSystemProps;
  lineProps?: StyledSystemProps;
  children: React.ReactNode;
}

export function DividerLabel({ labelProps, lineProps, children, ...props }: DividerLabelProps) {
  return (
    <Styled.Box {...props}>
      <Styled.Line {...lineProps} />
      <Styled.Content {...labelProps}>{children}</Styled.Content>
      <Styled.Line {...lineProps} />
    </Styled.Box>
  );
}
