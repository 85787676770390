import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPaymentJcb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="78"
    height="50"
    data-name="Jcb"
    viewBox="0 0 78 50"
    {...props}
  >
    <defs>
      <linearGradient id="payment-jcb_svg__a" x1="-57.53%" x2="232.39%" y1="50.12%" y2="50.12%">
        <stop offset="0%" stopColor="#007940" />
        <stop offset="22.85%" stopColor="#00873F" />
        <stop offset="74.33%" stopColor="#40A737" />
        <stop offset="100%" stopColor="#5CB531" />
      </linearGradient>
      <linearGradient id="payment-jcb_svg__b" x1="-62.8%" x2="253.67%" y1="49.86%" y2="49.86%">
        <stop offset="0%" stopColor="#007940" />
        <stop offset="22.85%" stopColor="#00873F" />
        <stop offset="74.33%" stopColor="#40A737" />
        <stop offset="100%" stopColor="#5CB531" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#A4A4AD"
        d="M5.77 0h66.46c2 0 2.73.2 3.47.6.73.4 1.3.97 1.7 1.7.4.74.6 1.46.6 3.47v38.46c0 2-.2 2.73-.6 3.47-.4.73-.97 1.3-1.7 1.7-.74.4-1.46.6-3.47.6H5.77c-2 0-2.73-.2-3.47-.6-.73-.4-1.3-.97-1.7-1.7-.4-.74-.6-1.46-.6-3.47V5.77c0-2 .2-2.73.6-3.47C1 1.57 1.57 1 2.3.6 3.04.2 3.76 0 5.77 0"
      />
      <path
        fill="#FFF"
        d="M5.99 1.5H72c1.56 0 2.13.16 2.7.47.57.3 1.02.75 1.32 1.32s.47 1.14.47 2.7V44c0 1.56-.16 2.13-.47 2.7-.3.57-.75 1.02-1.32 1.32s-1.14.47-2.7.47H6c-1.56 0-2.13-.16-2.7-.47a3.2 3.2 0 0 1-1.32-1.32c-.3-.57-.47-1.14-.47-2.7V6c0-1.56.16-2.13.47-2.7.3-.57.75-1.02 1.32-1.32s1.14-.47 2.7-.47z"
      />
      <path
        fill="#FFF"
        d="M5.77 0h66.46c2 0 2.73.2 3.47.6.73.4 1.3.97 1.7 1.7.4.74.6 1.46.6 3.47v38.46c0 2-.2 2.73-.6 3.47-.4.73-.97 1.3-1.7 1.7-.74.4-1.46.6-3.47.6H5.77c-2 0-2.73-.2-3.47-.6-.73-.4-1.3-.97-1.7-1.7-.4-.74-.6-1.46-.6-3.47V5.77c0-2 .2-2.73.6-3.47C1 1.57 1.57 1 2.3.6 3.04.2 3.76 0 5.77 0"
      />
      <g fillRule="nonzero">
        <path
          fill="#FFF"
          d="M63.13 36.21c0 4.2-3.42 7.63-7.63 7.63H14V13.63C14 9.43 17.42 6 21.63 6h41.5z"
        />
        <path
          fill="url(#payment-jcb_svg__a)"
          d="M35.62 22.46h3.15l.4-.03a1.4 1.4 0 0 0 1.1-1.4c0-.73-.5-1.27-1.1-1.42-.1-.03-.28-.03-.4-.03h-3.15z"
          transform="translate(14 6)"
        />
        <path
          fill="#1C953B"
          d="M52.41 8.55c-3 0-5.47 2.44-5.47 5.47v5.67h7.72c.18 0 .4 0 .54.03 1.74.1 3.04 1 3.04 2.56 0 1.23-.87 2.28-2.5 2.49v.06c1.78.12 3.13 1.11 3.13 2.64 0 1.65-1.5 2.74-3.49 2.74h-8.47v11.1h8.02c3 0 5.47-2.43 5.47-5.46V8.55h-8z"
        />
        <path
          fill="url(#payment-jcb_svg__b)"
          d="M39.88 16.64c0-.72-.5-1.2-1.11-1.3l-.3-.02h-2.85v2.64h2.85c.09 0 .27 0 .3-.03.6-.1 1.11-.57 1.11-1.3z"
          transform="translate(14 6)"
        />
        <path
          fill="#004E94"
          d="M22.2 8.55c-3 0-5.47 2.44-5.47 5.47V27.5a10.8 10.8 0 0 0 4.72 1.23c1.89 0 2.91-1.14 2.91-2.7v-6.37h4.69V26c0 2.46-1.54 4.48-6.73 4.48-3.15 0-5.62-.7-5.62-.7v11.5h8.02c3 0 5.47-2.43 5.47-5.46V8.55h-8z"
        />
        <path
          fill="#BE1833"
          d="M37.3 8.55c-3 0-5.46 2.44-5.46 5.47v7.15c1.38-1.18 3.78-1.93 7.66-1.75 2.07.1 4.3.66 4.3.66v2.32a10.4 10.4 0 0 0-4.15-1.2c-2.95-.21-4.72 1.23-4.72 3.75 0 2.55 1.77 4 4.72 3.75a10.9 10.9 0 0 0 4.14-1.2v2.31s-2.2.58-4.3.67c-3.87.18-6.27-.58-7.65-1.75v12.62h8.02c3 0 5.46-2.44 5.46-5.47V8.55z"
        />
      </g>
      <path
        fill="#A4A4AD"
        fillOpacity={0.65}
        fillRule="nonzero"
        d="M72.23 0c2 0 2.73.2 3.47.6.73.4 1.3.97 1.7 1.7.4.74.6 1.46.6 3.47v39c-.04 1.6-.25 2.26-.6 2.93-.4.73-.97 1.3-1.7 1.7-.74.4-1.46.6-3.47.6h-67c-1.6-.04-2.26-.25-2.93-.6-.73-.4-1.3-.97-1.7-1.7-.37-.7-.58-1.4-.6-3.2V5.78c0-2 .2-2.73.6-3.47S1.57 1 2.3.6C3 .23 3.7.02 5.5 0zM73 1.5H5A3.5 3.5 0 0 0 1.5 5v40A3.5 3.5 0 0 0 5 48.5h68a3.5 3.5 0 0 0 3.5-3.5V5A3.5 3.5 0 0 0 73 1.5"
      />
    </g>
  </svg>
);
export default SvgPaymentJcb;
