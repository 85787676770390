import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPaymentAmex = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="24"
    fill="none"
    data-name="Amex"
    viewBox="0 0 34 24"
    {...props}
  >
    <rect width={34} height={24} fill="#1F72CD" rx={4} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m6.095 8.5-3.18 7.247h3.808l.472-1.156h1.079l.472 1.156h4.192v-.882l.373.882h2.168l.374-.9v.9h8.718l1.06-1.126.992 1.126 4.478.01-3.191-3.613L31.101 8.5h-4.408L25.66 9.605 24.7 8.5h-9.484l-.814 1.87-.834-1.87h-3.8v.852L9.345 8.5zm.737 1.029H8.69l2.11 4.914V9.53h2.033l1.63 3.523 1.502-3.523h2.023v5.2h-1.23l-.011-4.075-1.795 4.075H13.85l-1.805-4.075v4.075H9.512l-.48-1.166H6.438l-.48 1.165H4.602L6.833 9.53zm17.288 0h-5.006v5.197h4.928l1.589-1.722 1.531 1.722h1.6l-2.326-2.583 2.327-2.614H27.23l-1.58 1.703-1.531-1.703zm-16.385.88-.854 2.077h1.708zm12.615 1.146v-.95h3.124l1.363 1.518-1.424 1.526H20.35v-1.036h2.731v-1.058z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPaymentAmex;
