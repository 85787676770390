import { useRouteMatch } from 'react-router-dom';

import * as Styled from './LogoBar.styled';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

export function LogoBar(boxProps: StyledSystemProps) {
  const isReceiptPage = useRouteMatch('/:visitCode/receipt');

  const logoFill = isReceiptPage ? '#fff' : '#000';

  return (
    <Styled.Box {...boxProps}>
      <Styled.Logo fill={logoFill} />
    </Styled.Box>
  );
}
