import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPaymentDiscover = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="24"
    fill="none"
    data-name="Discover"
    viewBox="0 0 34 24"
    {...props}
  >
    <rect width={33} height={23} x={0.5} y={0.5} fill="#fff" stroke="#D9D9D9" rx={3.5} />
    <path fill="#FD6020" d="m14 23 19-5.75V20a3 3 0 0 1-3 3z" />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M29.394 9.11c1.045 0 1.62.484 1.62 1.398.052.698-.418 1.29-1.045 1.397l1.41 1.988h-1.097l-1.202-1.935h-.104v1.934h-.889V9.111h1.307zm-.418 2.204h.26c.576 0 .837-.269.837-.752 0-.43-.261-.699-.836-.699h-.261v1.45zm-3.973 2.579h2.51v-.806h-1.621v-1.29h1.568v-.806h-1.568V9.917h1.62V9.11h-2.509v4.781zm-2.613-1.559-1.202-3.223h-.94L22.18 14h.47l1.934-4.89h-.94l-1.255 3.225zm-10.61-.805c0 1.343 1.046 2.471 2.352 2.471.418 0 .784-.107 1.15-.269v-1.074c-.261.322-.627.537-1.045.537-.836 0-1.516-.645-1.516-1.504v-.108a1.59 1.59 0 0 1 1.464-1.665c.418 0 .836.215 1.097.537V9.379c-.313-.214-.732-.268-1.097-.268-1.36-.108-2.405 1.02-2.405 2.418m-1.62-.591c-.522-.215-.68-.323-.68-.591.053-.323.314-.591.628-.538.261 0 .523.161.732.376l.47-.644c-.366-.323-.836-.538-1.306-.538-.732-.053-1.36.538-1.412 1.29v.054c0 .644.262 1.02 1.098 1.289.209.054.418.161.627.269a.57.57 0 0 1 .261.483c0 .376-.313.699-.627.699H9.9c-.418 0-.784-.269-.94-.645l-.576.59c.314.592.941.914 1.568.914.836.054 1.516-.59 1.568-1.45v-.162c-.052-.644-.313-.967-1.359-1.396zm-3.03 2.954h.888V9.111H7.13zM3 9.111h1.567c1.255.054 2.248 1.128 2.196 2.418 0 .698-.314 1.343-.836 1.826-.47.376-1.045.591-1.62.538H3V9.11zm1.15 3.976c.418.053.888-.108 1.202-.377.313-.322.47-.752.47-1.235 0-.43-.157-.86-.47-1.182-.314-.269-.784-.43-1.202-.376h-.262v3.17z"
      clipRule="evenodd"
    />
    <path
      fill="#FD6020"
      fillRule="evenodd"
      d="M17.948 9c-1.307 0-2.405 1.075-2.405 2.471 0 1.344 1.046 2.472 2.405 2.526s2.404-1.075 2.456-2.472C20.352 10.128 19.306 9 17.948 9"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPaymentDiscover;
