import { useEffect, useRef, useState } from 'react';

import InfoIcon from '@m/assets/svg/info';
import { type Visit } from '@m/types/api';
import { heapTrackProperties } from '@m/utils/heap';

import {
  getDuration,
  getTotalPrice,
  getParkingCost,
  getFeeAmount,
  getValidationAmount,
  getLicensePlate,
  getEntryDate,
  getEntryTime,
  getExitDate,
  getExitTime,
  getTaxAmount,
  getCoverageDuration,
  getBillableDuration,
} from 'apps/pay/utils/visitUtils';

import { DetailLineItem } from '../DetailLineItem';
import { Separator } from '../Separator';
import { Text } from '../Text';

import * as Styled from './VisitInfo.styled';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

interface VisitInfoProps extends StyledSystemProps {
  visit: Visit | null;
  onClickNotMyVehicle: () => void;
  onClickServiceFeeInfo: () => void;
}

export function VisitInfo({
  visit,
  onClickNotMyVehicle,
  onClickServiceFeeInfo,
  ...viewProps
}: VisitInfoProps) {
  const hasExpandedDetails = useRef(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const hasCoverage = !!visit?.coverage?.reservations?.length;

  useEffect(() => {
    // Send tracking event if details expanded,
    // but only the first time it's triggered
    if (!hasExpandedDetails.current && isExpanded) {
      heapTrackProperties('memberless-payment/landing/details:expanded', {
        'timestamp(utc)': new Date().toUTCString(),
        timestamp: Date.now(),
      });
      hasExpandedDetails.current = true;
    }
  }, [isExpanded, visit]);

  return (
    <Styled.View {...viewProps}>
      <Styled.Overview>
        <Styled.OverviewContent>
          <Styled.TotalPrice>{getTotalPrice(visit)}</Styled.TotalPrice>
          <Styled.OverviewDetails>
            <Styled.OverviewDescription isVisible={!isExpanded}>
              Parking visit cost
            </Styled.OverviewDescription>
            <Styled.OverviewData isVisible={isExpanded}>
              <span>{getDuration(visit)}</span>
              <span>&nbsp;&bull;&nbsp;</span>
              <span>{getLicensePlate(visit)}</span>
            </Styled.OverviewData>
          </Styled.OverviewDetails>
        </Styled.OverviewContent>
        <Styled.DetailButton
          isExpanded={isExpanded}
          onClick={() => setIsExpanded((prevExpanded) => !prevExpanded)}
        >
          <Styled.DetailButtonLabel isVisible={!isExpanded}>Details</Styled.DetailButtonLabel>
          <Styled.DetailButtonLabel isVisible={isExpanded}>Hide</Styled.DetailButtonLabel>
          <Styled.Chevron isExpanded={isExpanded} />
        </Styled.DetailButton>
      </Styled.Overview>
      <Styled.Details isExpanded={isExpanded}>
        <Styled.DetailContent>
          <DetailLineItem
            line1Label="License plate"
            line1Content={getLicensePlate(visit)}
            line2Content={
              <Styled.VehicleConfirmButton onClick={onClickNotMyVehicle}>
                Not your car?
              </Styled.VehicleConfirmButton>
            }
          />
          <DetailLineItem
            line1Label="Entry"
            line1Content={getEntryDate(visit)}
            line2Label={<>Date &amp; time</>}
            line2Content={getEntryTime(visit)}
          />
          <DetailLineItem
            line1Label="Exit"
            line1Content={getExitDate(visit)}
            line2Label={<>Date &amp; time</>}
            line2Content={getExitTime(visit)}
          />
          <DetailLineItem line1Label="Duration" line1Content={getDuration(visit)} />
          <Separator />
          {!!visit?.coverage?.reservations?.length && (
            <DetailLineItem
              line1Label="Covered by Reservation"
              line1Content="$0.00"
              line2Label={getCoverageDuration(visit?.coverage?.reservations)}
            />
          )}
          {!!visit?.coverage?.parkingPasses?.length && (
            <DetailLineItem
              line1Label="Covered by Parking pass"
              line1Content="$0.00"
              line2Label={getCoverageDuration(visit?.coverage?.parkingPasses)}
            />
          )}
          <DetailLineItem
            line1Label={hasCoverage ? 'Additional time' : 'Parking cost'}
            line1Content={getParkingCost(visit)}
            line2Label={hasCoverage ? getBillableDuration(visit) : getDuration(visit)}
          />
          {getValidationAmount(visit) && (
            <DetailLineItem
              line1Label="Validation"
              line1Content={<Text color="#06944F">{getValidationAmount(visit)}</Text>}
            />
          )}
          {getTaxAmount(visit) && (
            <DetailLineItem line1Label="Tax" line1Content={getTaxAmount(visit)} />
          )}
          {getFeeAmount(visit) && (
            <DetailLineItem
              line1Label={
                <>
                  Service fee
                  <Styled.FeeInfoButton onClick={onClickServiceFeeInfo}>
                    <InfoIcon />
                  </Styled.FeeInfoButton>
                </>
              }
              line1Content={getFeeAmount(visit)}
            />
          )}
          <Separator />
          <DetailLineItem
            line1Label={<Styled.TotalPrice fontSize="28px">Total</Styled.TotalPrice>}
            line1Content={
              <Styled.TotalPrice fontSize="28px">{getTotalPrice(visit)}</Styled.TotalPrice>
            }
          />
        </Styled.DetailContent>
      </Styled.Details>
    </Styled.View>
  );
}
