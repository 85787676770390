import type { ReactNode } from 'react';

import * as Styled from './DetailLineItem.styled';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

interface DetailLineItemProps extends StyledSystemProps {
  line1Label: ReactNode;
  line1Content: ReactNode;
  line2Label?: ReactNode;
  line2Content?: ReactNode;
}

export function DetailLineItem({
  line1Label,
  line1Content,
  line2Label,
  line2Content,
  ...boxProps
}: DetailLineItemProps) {
  const hasLine2 = line2Label || line2Content;

  return (
    <Styled.Box minHeight={hasLine2 ? 62 : 40} {...boxProps}>
      <Styled.LineRow>
        <Styled.LineColumn>{line1Label}</Styled.LineColumn>
        <Styled.LineColumn>{line1Content}</Styled.LineColumn>
      </Styled.LineRow>
      {hasLine2 && (
        <Styled.LineRow>
          <Styled.LineColumn opacity={0.6}>{line2Label ?? <>&nbsp;</>}</Styled.LineColumn>
          <Styled.LineColumn>{line2Content ?? <>&nbsp;</>}</Styled.LineColumn>
        </Styled.LineRow>
      )}
    </Styled.Box>
  );
}
