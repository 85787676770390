import { useState } from 'react';

import AmEx from '@m/assets/svg/payment-amex';
import DinersClub from '@m/assets/svg/payment-dinersclub';
import Discover from '@m/assets/svg/payment-discover';
import MasterCard from '@m/assets/svg/payment-mastercard';
import Visa from '@m/assets/svg/payment-visa';
import LoadingSpinner from '@m/components/LoadingSpinner';
import { Box } from '@m/components/uikit';
import { cardOptions } from '@m/constants/StripeOptions';
import { type Visit } from '@m/types/api';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { type StripeCardElement } from '@stripe/stripe-js';

// These are imported directly from the source file to
// avoid circular dependencies.
import { DividerLabel } from '../DividerLabel/DividerLabel';
import { Heading } from '../Heading/Heading.styled';
import { PageSection } from '../Page/Page.styled';
import { Text } from '../Text/Text.styled';

import * as Styled from './PayWithCard.styled';

interface PayWithCardProps {
  visit: Visit | null;
  supportsPaymentRequest: boolean | null;
  onCardTokenReceived: (token: string) => void;
}

export function PayWithCard({
  visit,
  supportsPaymentRequest,
  onCardTokenReceived,
}: PayWithCardProps) {
  const stripe = useStripe();
  const elements = useElements();

  const [canSubmit, setCanSubmit] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    const res = await stripe.createToken(elements.getElement(CardElement) as StripeCardElement);

    if (res.token) {
      onCardTokenReceived(res.token.id);
    }
  };

  return (
    <PageSection>
      {supportsPaymentRequest && <DividerLabel marginBottom="24px">Or pay with card</DividerLabel>}
      <Heading>Payment method</Heading>
      <Text>All transactions are secure and encrypted.</Text>
      <Box
        marginTop="24px"
        marginBottom="36px"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
        css={`
          & svg {
            margin-right: 12px;
          }
        `}
      >
        <Visa width={45} height={32} />
        <MasterCard width={45} height={32} />
        <AmEx width={45} height={32} />
        <Discover width={45} height={32} />
        <DinersClub width={45} height={32} />
      </Box>
      <Box
        width="100%"
        flexDirection="row"
        justifyContent="center"
        alignItems="flex-start"
        height="auto"
        minHeight="60px"
        overflow="hidden"
        position="relative"
      >
        <CardElement
          options={cardOptions}
          onChange={(event) => (event.complete ? setCanSubmit(true) : setCanSubmit(false))}
          css={`
            width: 100%;
            height: 60px;
            border: 2px solid #c4c4c4;
            border-radius: 4px;
            padding: 18px;
          `}
        />
      </Box>
      <Styled.PayButton
        variant="primary"
        disabled={!canSubmit || processing}
        marginY="24px"
        onClick={handleSubmit}
      >
        {visit?.itemizedAmounts?.totalPriceAmount && !processing ? (
          <>
            {`Pay $${visit.itemizedAmounts.totalPriceAmount.toFixed(2)}`}
            <Styled.LockIcon />
          </>
        ) : (
          <LoadingSpinner color="#fff" />
        )}
      </Styled.PayButton>
      <Text
        color="rgba(0,0,0,0.6)"
        fontSize="14px"
        fontWeight={400}
        lineHeight={1.71428571}
        letterSpacing="0.01em"
      >
        <Text>By tapping Pay, you agree to the&#x20;</Text>
        <Styled.PolicyLink href="https://metropolis.io/terms/">Terms of Service</Styled.PolicyLink>

        <Text>&#x20;and</Text>
        <Styled.PolicyLink href="https://metropolis.io/privacy/">
          <Text>Data and Privacy Policy</Text>
        </Styled.PolicyLink>
      </Text>
    </PageSection>
  );
}
