import Configurator from '@m/config/Configurator';
import { loadStripe } from '@stripe/stripe-js/pure';

import type { Stripe } from '@stripe/stripe-js';

let loadStripePromise: Promise<Stripe | null> | undefined;

function stripeLoader() {
  if (!loadStripePromise) {
    // TODO: Do we want to enable fraud signals?
    // They essentially track our webpage. May impact our ToS/PP
    // https://stripe.com/docs/disputes/prevention/advanced-fraud-detection
    loadStripe.setLoadParameters({ advancedFraudSignals: false });
    loadStripePromise = loadStripe(Configurator.STRIPE_PUBLISHABLE_KEY);
  }
  return loadStripePromise;
}

/**
 * We export `stripePromise` using the stripeLoader() function
 * which saves that value in a module scoped variable so that stripe
 * is only initialized once and won't break when hot-reloading
 */
export const stripePromise = stripeLoader();
