import { type ReactNode } from 'react';

import { ONE_MINUTE_IN_MILLISECONDS } from '@m/constants/Time';
import { type Visit } from '@m/types/api';
import { BaseCoverage } from '@m/types/api/Coverage';
import { convertNumberToDollarFormat } from '@m/utils/convertNumberToDollarFormat';
import { DateTime, Duration } from 'luxon';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

export function getCoverageDuration(coverages: BaseCoverage[] | null | undefined) {
  let durationText: ReactNode = <Skeleton width="3em" height="1.1em" />;
  const reservationCoveredMs =
    (coverages?.reduce((acc, curr) => acc + curr.coveredMinutes, 0) || 0) * 1000 * 60;
  if (coverages?.length) {
    const duration = Duration.fromMillis(reservationCoveredMs);
    const [hours, minutes] = duration.toFormat('hh:mm').split(':').map(Number);
    const hoursText = hours ? `${hours}h` : null;
    const minutesText = minutes ? `${minutes}m` : null;

    durationText = [hoursText, minutesText].filter(Boolean).join(' ');

    // If the reservation duration is less than one minute,
    // `durationText` will be an empty string.
    // In this case, we want to display "1m" instead.
    if (!durationText) {
      return '1m';
    }
  }
  return durationText;
}

export function getBillableDuration(visit: Visit | null) {
  let durationText: ReactNode = <Skeleton width="3em" height="1.1em" />;
  if (visit?.coverage?.reservations?.length) {
    const duration = Duration.fromMillis(
      (visit?.billableMinutes || 0) * ONE_MINUTE_IN_MILLISECONDS,
    );
    const [hours, minutes] = duration.toFormat('hh:mm').split(':').map(Number);
    const hoursText = hours ? `${hours}h` : null;
    const minutesText = minutes ? `${minutes}m` : null;

    durationText = [hoursText, minutesText].filter(Boolean).join(' ');

    if (!durationText) {
      return '0m';
    }
  } else {
    return getDuration(visit);
  }
  return durationText;
}

export function getDuration(visit: Visit | null) {
  let durationText: ReactNode = <Skeleton width="3em" height="1.1em" />;
  if (visit?.start && visit?.end) {
    const duration = Duration.fromMillis(visit.end - visit.start);
    const [hours, minutes] = duration.toFormat('hh:mm').split(':').map(Number);
    const hoursText = hours ? `${hours}h` : null;
    const minutesText = minutes ? `${minutes}m` : null;

    durationText = [hoursText, minutesText].filter(Boolean).join(' ');

    // If the visit duration is less than one minute,
    // `durationText` will be an empty string.
    // In this case, we want to display "1m" instead.
    if (!durationText) {
      return '1m';
    }
  }
  return durationText;
}

export function getTotalPrice(visit: Visit | null) {
  if (visit?.itemizedAmounts) {
    return `$${visit.itemizedAmounts.totalPriceAmount.toFixed(2)}`;
  }
  return <Skeleton width="3.5em" height="1em" />;
}

export function getParkingCost(visit: Visit | null) {
  if (visit?.itemizedAmounts) {
    return `$${visit.itemizedAmounts.price.toFixed(2)}`;
  }
  return <Skeleton width="3.5em" height="1em" />;
}

export function getTaxAmount(visit: Visit | null) {
  if (visit?.itemizedAmounts?.taxAmount) {
    return convertNumberToDollarFormat({
      price: visit.itemizedAmounts.taxAmount,
      includeZeros: true,
    });
  }
  return null;
}

export function getFeeAmount(visit: Visit | null) {
  if (visit?.itemizedAmounts?.feeAmount) {
    return `$${visit.itemizedAmounts.feeAmount.toFixed(2)}`;
  }
  return null;
}

export function getValidationAmount(visit: Visit | null) {
  if (visit?.itemizedAmounts?.validationAmount) {
    return `-$${visit.itemizedAmounts.validationAmount.toFixed(2)}`;
  }
  return null;
}

export function getLicensePlate(visit: Visit | null) {
  if (visit?.vehicle) {
    return visit.vehicle.licensePlate.text;
  }
  return <Skeleton width="4em" height="1.1em" />;
}

export function getEntryDate(visit: Visit | null) {
  if (visit?.start) {
    return DateTime.fromMillis(visit.start).toFormat('LLL dd, yyyy');
  }
  return <Skeleton width="5.25em" height="1.1em" />;
}
export function getEntryTime(visit: Visit | null) {
  if (visit?.start) {
    return DateTime.fromMillis(visit.start).toFormat('h:mm a');
  }
  return <Skeleton width="3.5em" height="1.1em" />;
}

export function getExitDate(visit: Visit | null) {
  if (visit?.end) {
    return DateTime.fromMillis(visit.end).toFormat('LLL dd, yyyy');
  }
  return <Skeleton width="5.25em" height="1.1em" />;
}
export function getExitTime(visit: Visit | null) {
  if (visit?.end) {
    return DateTime.fromMillis(visit.end).toFormat('h:mm a');
  }
  return <Skeleton width="3.5em" height="1.1em" />;
}

export function getSiteName(visit: Visit | null) {
  if (visit?.site) {
    return visit.site.name;
  }
  return <Skeleton width="9em" height="1.1em" />;
}

export function getSiteAddress1(visit: Visit | null) {
  if (visit?.site) {
    return visit.site.address_1;
  }
  return <Skeleton width="11em" height="1.1em" />;
}

export function getSiteAddress2(visit: Visit | null) {
  if (visit?.site) {
    return `${visit.site.city}, ${visit.site.stateCode} ${visit.site.zipcode}`;
  }
  return <Skeleton width="8em" height="1.1em" />;
}
