import * as Styled from './SuccessMessage.styled';

import type { StyledSystemProps } from '@m/theme/helpers/withStyledSystem';

interface SuccessMessageProps extends StyledSystemProps {
  icon?: React.ReactNode;
  message: React.ReactNode;
  description?: React.ReactNode;
}

export function SuccessMessage({
  icon = null,
  message,
  description,
  ...viewProps
}: SuccessMessageProps) {
  return (
    <Styled.View {...viewProps}>
      <Styled.IconBox>{icon}</Styled.IconBox>
      <Styled.MessageBox>
        <Styled.Message>{message}</Styled.Message>
        {description && <Styled.Description>{description}</Styled.Description>}
      </Styled.MessageBox>
    </Styled.View>
  );
}
