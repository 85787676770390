import { useEffect } from 'react';

import { type Visit } from '@m/types/api';
import { useHistory, useParams } from 'react-router-dom';

/**
 * Will redirect to the payment page if the visit is not paid for
 */
export function useOpenVisitRedirect(visit: Visit | null) {
  const history = useHistory();
  const { visitCode } = useParams<{ visitCode: string }>();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (visit && visit.open) {
      // Delay redirect to prevent flash of landing page
      timeoutId = setTimeout(() => {
        history.replace(`/${visitCode}`);
      }, 300);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [visit, visitCode, history]);
}
