import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPaymentCreditcard = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="78" height="50" viewBox="0 0 78 50" {...props}>
    <defs>
      <linearGradient id="payment-creditcard_svg__a" x1="0%" x2="100%" y1="29.45%" y2="70.55%">
        <stop offset="0%" stopColor="#EDF1F3" />
        <stop offset="100%" stopColor="#D3DADF" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#A4A4AD"
        d="M5.77 0h66.46c2 0 2.73.2 3.47.6.73.4 1.3.97 1.7 1.7.4.74.6 1.46.6 3.47v38.46c0 2-.2 2.73-.6 3.47-.4.73-.97 1.3-1.7 1.7-.74.4-1.46.6-3.47.6H5.77c-2 0-2.73-.2-3.47-.6-.73-.4-1.3-.97-1.7-1.7-.4-.74-.6-1.46-.6-3.47V5.77c0-2 .2-2.73.6-3.47C1 1.57 1.57 1 2.3.6 3.04.2 3.76 0 5.77 0"
      />
      <path
        fill="#FFF"
        d="M5.99 1.5H72c1.56 0 2.13.16 2.7.47.57.3 1.02.75 1.32 1.32s.47 1.14.47 2.7V44c0 1.56-.16 2.13-.47 2.7-.3.57-.75 1.02-1.32 1.32s-1.14.47-2.7.47H6c-1.56 0-2.13-.16-2.7-.47a3.2 3.2 0 0 1-1.32-1.32c-.3-.57-.47-1.14-.47-2.7V6c0-1.56.16-2.13.47-2.7.3-.57.75-1.02 1.32-1.32s1.14-.47 2.7-.47z"
      />
      <path
        fill="url(#payment-creditcard_svg__a)"
        d="M5.77 0h66.46c2 0 2.73.2 3.47.6.73.4 1.3.97 1.7 1.7.4.74.6 1.46.6 3.47v38.46c0 2-.2 2.73-.6 3.47-.4.73-.97 1.3-1.7 1.7-.74.4-1.46.6-3.47.6H5.77c-2 0-2.73-.2-3.47-.6-.73-.4-1.3-.97-1.7-1.7-.4-.74-.6-1.46-.6-3.47V5.77c0-2 .2-2.73.6-3.47C1 1.57 1.57 1 2.3.6 3.04.2 3.76 0 5.77 0"
      />
      <path
        fill="#BBB"
        d="M5.77 0h66.46c2 0 2.73.2 3.47.6.73.4 1.3.97 1.7 1.7.4.74.6 1.46.6 3.47v38.46c0 2-.2 2.73-.6 3.47-.4.73-.97 1.3-1.7 1.7-.74.4-1.46.6-3.47.6H5.77c-2 0-2.73-.2-3.47-.6-.73-.4-1.3-.97-1.7-1.7-.4-.74-.6-1.46-.6-3.47V5.77c0-2 .2-2.73.6-3.47C1 1.57 1.57 1 2.3.6 3.04.2 3.76 0 5.77 0"
      />
      <path
        fill="#E2DFFF"
        d="M5.77 0h66.46c2 0 2.73.2 3.47.6.73.4 1.3.97 1.7 1.7.4.74.6 1.46.6 3.47v38.46c0 2-.2 2.73-.6 3.47-.4.73-.97 1.3-1.7 1.7-.74.4-1.46.6-3.47.6H5.77c-2 0-2.73-.2-3.47-.6-.73-.4-1.3-.97-1.7-1.7-.4-.74-.6-1.46-.6-3.47V5.77c0-2 .2-2.73.6-3.47C1 1.57 1.57 1 2.3.6 3.04.2 3.76 0 5.77 0"
        opacity={0.42}
      />
      <path
        fill="#F6F6FF"
        d="M6.28 36h18.44c.44 0 .6.05.77.13.16.1.29.22.38.38.08.16.13.33.13.77v3.44c0 .44-.05.6-.13.77a.9.9 0 0 1-.38.38c-.16.08-.33.13-.77.13H6.28c-.44 0-.6-.05-.77-.13a.9.9 0 0 1-.38-.38c-.08-.16-.13-.33-.13-.77v-3.44c0-.44.05-.6.13-.77a.9.9 0 0 1 .38-.38c.16-.08.33-.13.77-.13m23.5 0h18.44c.44 0 .6.05.77.13.16.1.29.22.38.38.08.16.13.33.13.77v3.44c0 .44-.05.6-.13.77a.9.9 0 0 1-.38.38c-.16.08-.33.13-.77.13H29.78c-.44 0-.6-.05-.77-.13a.9.9 0 0 1-.38-.38c-.08-.16-.13-.33-.13-.77v-3.44c0-.44.05-.6.13-.77a.9.9 0 0 1 .38-.38c.16-.08.33-.13.77-.13m23.5 0h18.44c.44 0 .6.05.77.13.16.1.29.22.38.38.08.16.13.33.13.77v3.44c0 .44-.05.6-.13.77a.9.9 0 0 1-.38.38c-.16.08-.33.13-.77.13H53.28c-.44 0-.6-.05-.77-.13a.9.9 0 0 1-.38-.38c-.08-.16-.13-.33-.13-.77v-3.44c0-.44.05-.6.13-.77a.9.9 0 0 1 .38-.38c.16-.08.33-.13.77-.13"
      />
      <path
        fill="#606065"
        d="M53.28 8h18.44c.44 0 .6.05.77.13.16.1.29.22.38.38.08.16.13.33.13.77v12.44c0 .44-.05.6-.13.77a.9.9 0 0 1-.38.38c-.16.08-.33.13-.77.13H53.28c-.44 0-.6-.05-.77-.13a.9.9 0 0 1-.38-.38c-.08-.16-.13-.33-.13-.77V9.28c0-.44.05-.6.13-.77a.9.9 0 0 1 .38-.38c.16-.08.33-.13.77-.13"
      />
      <path
        fill="#A4A4AD"
        fillOpacity={0.65}
        fillRule="nonzero"
        d="M72.23 0c2 0 2.73.2 3.47.6.73.4 1.3.97 1.7 1.7.4.74.6 1.46.6 3.47v39c-.04 1.6-.25 2.26-.6 2.93-.4.73-.97 1.3-1.7 1.7-.74.4-1.46.6-3.47.6h-67c-1.6-.04-2.26-.25-2.93-.6-.73-.4-1.3-.97-1.7-1.7-.37-.7-.58-1.4-.6-3.2V5.78c0-2 .2-2.73.6-3.47S1.57 1 2.3.6C3 .23 3.7.02 5.5 0zM73 1.5H5A3.5 3.5 0 0 0 1.5 5v40A3.5 3.5 0 0 0 5 48.5h68a3.5 3.5 0 0 0 3.5-3.5V5A3.5 3.5 0 0 0 73 1.5"
      />
    </g>
  </svg>
);
export default SvgPaymentCreditcard;
