import { apiFetch } from '@m/utils/http';

export const PaymentService = {
  /**
   * Get visit info
   */
  getVisitDetails: ({ visitCode }: { visitCode: string }) =>
    apiFetch(`/customer/text-to-pay/details/${visitCode}`),

  /**
   * Complete visit payment via Stripe token
   */
  completePayment: ({
    visitCode,
    ...body
  }: {
    visitCode: string;
    token: string;
    isBrowserSavedCard?: boolean;
    saveForLater?: boolean;
  }) =>
    apiFetch(`/customer/text-to-pay/complete/${visitCode}`, {
      method: 'POST',
      body,
    }),

  /**
   * Confirm visit has incorrect vehicle plate number
   */
  wrongVehicle: ({ visitCode }: { visitCode: string }) =>
    apiFetch(`/customer/text-to-pay/wrong-vehicle/${visitCode}`, { method: 'PUT' }),
};
